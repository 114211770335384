var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.fetchinData)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("Payment")))])],1),_c('v-card-text',[_c('table',{staticClass:"default-table font-weight-regular"},[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("status")))]),_c('td',[(_vm.payData.payment.paymentStatus === 'completed')?_c('v-chip',{staticClass:"white--text",attrs:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.$t("Complete"))+" ")]):_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.payData.underageParticipant === '1' ? 'orange' : 'light-green'}},[_vm._v(" "+_vm._s(_vm.$t("SepaMissing"))+" ")]),(
              _vm.payData.payment.paymentStatus !== 'completed' &&
              _vm.data.underageParticipant !== '1'
            )?[_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","small":"","color":"primary","loading":_vm.progress},on:{"click":function($event){return _vm.sendReminder()}}},[_vm._v(" Reminder senden ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","small":"","color":"primary","loading":_vm.progress},on:{"click":function($event){return _vm.stopReminder()}}},[_vm._v(" Reminder stoppen ")])]:_vm._e(),(_vm.payData.underageParticipant === '1')?_c('span',{staticClass:"caption mx-2"},[_vm._v(" ("+_vm._s(_vm.$t("underageParticipant"))+") ")]):_vm._e()],2)]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("type")))]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.payData.payment.type))])])]),(
        _vm.payData.payment.reminderDates &&
        _vm.payData.payment.reminderDates.length > 0
      )?[_c('h3',[_vm._v("Versendete Zahlungserinnerungen")]),_c('table',{staticClass:"default-table"},[_c('tbody',{domProps:{"innerHTML":_vm._s(_vm.remindesDates(_vm.payData.payment.reminderDates))}})])]:_vm._e(),(_vm.payData.payment.type === 'sepa')?[_c('table',{staticClass:"default-table"},[_c('tbody',[(_vm.payData.payment.sepa.created)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("date")))]),_c('td',[_vm._v(" "+_vm._s(this.formatDate(_vm.payData.payment.sepa.created))+" ")])]):_vm._e(),(_vm.payData.payment.sepa.bankName)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Bank")]),_c('td',[_vm._v(" "+_vm._s(_vm.payData.payment.sepa.bankName)+" ")])]):_vm._e(),(_vm.payData.payment.sepa.accountHolder)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("accountHolder"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.payData.payment.sepa.accountHolder)+" ")])]):_vm._e(),(_vm.payData.payment.sepa.iban)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("IBAN")]),_c('td',[_vm._v(_vm._s(_vm.payData.payment.sepa.iban))])]):_vm._e(),(_vm.payData.payment.sepa.bic)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("BIC")]),_c('td',[_vm._v(_vm._s(_vm.payData.payment.sepa.bic))])]):_vm._e(),(_vm.payData.payment.sepa.payment_providers_id)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("PROVIDER")]),_c('td',[_vm._v(" "+_vm._s(_vm.payData.payment.sepa.payment_providers_id)+" ")])]):_vm._e()])])]:_vm._e()],2),(_vm.payData.payment.type === 'sepa')?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"purple","small":"","depressed":""},on:{"click":function($event){return _vm.downloadDocument(_vm.payData.payment.sepa.document)}}},[_vm._v("Download SEPA-Mandat PDF")])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }