<template>
  <v-card v-if="!fetchinData">
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>{{ $t("Payment") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <table class="default-table font-weight-regular">
        <tr>
          <td class="font-weight-bold">{{ $t("status") }}</td>
          <td>
            <v-chip
              v-if="payData.payment.paymentStatus === 'completed'"
              color="green"
              class="white--text"
            >
              {{ $t("Complete") }}
            </v-chip>

            <v-chip
              v-else
              :color="
                payData.underageParticipant === '1' ? 'orange' : 'light-green'
              "
              class="white--text"
            >
              {{ $t("SepaMissing") }}
            </v-chip>

            <template
              v-if="
                payData.payment.paymentStatus !== 'completed' &&
                data.underageParticipant !== '1'
              "
            >
              <v-btn
                depressed
                small
                color="primary"
                @click="sendReminder()"
                :loading="progress"
                class="ml-3"
              >
                Reminder senden
              </v-btn>

              <v-btn
                depressed
                small
                color="primary"
                @click="stopReminder()"
                :loading="progress"
                class="ml-3"
              >
                Reminder stoppen
              </v-btn>
            </template>

            <span
              v-if="payData.underageParticipant === '1'"
              class="caption mx-2"
            >
              ({{ $t("underageParticipant") }})
            </span>
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold">{{ $t("type") }}</td>
          <td class="font-weight-bold">{{ payData.payment.type }}</td>
        </tr>
      </table>

      <template
        v-if="
          payData.payment.reminderDates &&
          payData.payment.reminderDates.length > 0
        "
      >
        <h3>Versendete Zahlungserinnerungen</h3>
        <table class="default-table">
          <tbody v-html="remindesDates(payData.payment.reminderDates)"></tbody>
        </table>
      </template>

      <template v-if="payData.payment.type === 'sepa'">
        <table class="default-table">
          <tbody>
            <tr v-if="payData.payment.sepa.created">
              <td class="font-weight-bold">{{ $t("date") }}</td>
              <td>
                {{ this.formatDate(payData.payment.sepa.created) }}
              </td>
            </tr>
            <tr v-if="payData.payment.sepa.bankName">
              <td class="font-weight-bold">Bank</td>
              <td>
                {{ payData.payment.sepa.bankName }}
              </td>
            </tr>
            <tr v-if="payData.payment.sepa.accountHolder">
              <td class="font-weight-bold">
                {{ $t("accountHolder") }}
              </td>
              <td>
                {{ payData.payment.sepa.accountHolder }}
              </td>
            </tr>
            <tr v-if="payData.payment.sepa.iban">
              <td class="font-weight-bold">IBAN</td>
              <td>{{ payData.payment.sepa.iban }}</td>
            </tr>
            <tr v-if="payData.payment.sepa.bic">
              <td class="font-weight-bold">BIC</td>
              <td>{{ payData.payment.sepa.bic }}</td>
            </tr>
            <tr v-if="payData.payment.sepa.payment_providers_id">
              <td class="font-weight-bold">PROVIDER</td>
              <td>
                {{ payData.payment.sepa.payment_providers_id }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <!-- <pre>{{ payData }}</pre> -->
    </v-card-text>

    <v-card-actions v-if="payData.payment.type === 'sepa'">
      <v-spacer></v-spacer>
      <v-btn
        color="purple"
        small
        depressed
        class="white--text"
        @click="downloadDocument(payData.payment.sepa.document)"
        >Download SEPA-Mandat PDF</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "LeadPaymentCard",
  props: ["data"],

  data() {
    return {
      leadId: this.data.id,
      payData: this.data.pay,
      progress: false,
      currency: this.data.pay.currency ?? "NONE",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
    };
  },

  methods: {
    async sendReminder() {
      // Die Route POST pay/orders/{id}/sendReminder
      const orderId = this.payData.orders_id ?? null;

      if (!orderId) {
        alert("Keine Order ID vorhanden!");
        return;
      }

      this.progress = true;
      let response = await this.postRequest(
        `pay/orders/${orderId}/sendReminder`
      );
      this.progress = false;

      if (response.status === 200) {
        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Reminder wurde erfolgreich versendet!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async stopReminder() {
      // Neue Route PATCH /leads/{id}/stopPaymentReminder, es wird nun der Status geändert: 'status' => 'reminderStopped'

      if (!this.leadId) {
        alert("Keine Lead ID vorhanden!");
        return;
      }

      this.progress = true;
      let response = await this.patchRequest(
        `leads/${this.leadId}/stopPaymentReminder`
      );

      this.progress = false;

      if (response.status === 200) {
        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Reminder wurde erfolgreich gestoppt!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    remindesDates(dates) {
      let html = "";
      dates.forEach((date) => {
        html += `<tr><td class="caption">${this.formatDate(date)}</td></tr>`;
      });
      return html;
    },
  },

  mixins: [manageApi, helpers],
};
</script>
