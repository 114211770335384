<template>
  <v-card v-if="!fetchinData">
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>{{ $t("baseData") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <EditBasedata
        v-if="
          data.pay &&
          data.pay.type !== 'contract' &&
          data.pay.type !== 'product'
        "
        :data="data"
        class="align-content-end"
        @updatedBaseData="$emit('updatedBaseData', true)"
      />
    </v-toolbar>

    <v-card-text>
      <!-- <pre>{{ data }}</pre> -->
      <table class="default-table" style="width: 100%">
        <tbody>
          <tr
            v-if="
              data.pay &&
              data.pay.type !== 'contract' &&
              data.pay.type !== 'product'
            "
          >
            <td class="font-weight-bold">
              {{ $t("status") }}
            </td>

            <td v-if="data.identityIsClient === '1'">
              <v-chip color="green" text-color="white">
                <v-icon small left>mdi-trophy-variant</v-icon>
                {{ $t("client") }} </v-chip
              ><br />
            </td>
            <td v-else>
              <v-chip color="gray" text-color="black">
                <v-icon small left>mdi-trophy-variant-outline</v-icon>
                {{ $t("participant") }}
              </v-chip>
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("gender") }}</td>
            <td>{{ $t(data.gender) }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("fullname") }}</td>
            <td>{{ data.firstName }} {{ data.lastName }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("email") }}</td>
            <td v-if="data.email">
              <a :href="'mailto:' + data.email">{{ data.email }}</a>
            </td>
            <td v-else>-</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("Birthday") }}</td>
            <td v-if="data.birthday">
              {{ this.formatDateNoTime(data.birthday) }}

              <v-chip
                v-if="data.underageParticipant === '1'"
                small
                color="orange"
                class="white--text"
              >
                {{ $t("underageParticipant") }}
              </v-chip>
            </td>
            <td v-else>-</td>
          </tr>

          <tr v-if="data.phone">
            <td class="font-weight-bold">{{ $t("phone") }}</td>
            <td>{{ data.phone }}</td>
          </tr>

          <tr v-else-if="data.order && data.order.mobile">
            <td class="font-weight-bold">{{ $t("phone") }}</td>
            <td>{{ data.order.mobile }}</td>
          </tr>

          <tr v-if="data.mobile">
            <td class="font-weight-bold">{{ $t("mobile") }}</td>
            <td>{{ data.mobile }}</td>
          </tr>

          <tr v-else-if="data.order && data.order.phone">
            <td class="font-weight-bold">{{ $t("phone") }}</td>
            <td>{{ data.order.phone }}</td>
          </tr>

          <tr v-if="data.street">
            <td class="font-weight-bold">{{ $t("address") }}</td>
            <td>
              {{ data.street }}
              <span v-if="data.streetNumber">{{ data.streetNumber }}</span>
            </td>
          </tr>

          <tr v-else-if="data.order && data.order.street">
            <td class="font-weight-bold">{{ $t("address") }}</td>
            <td>
              {{ data.order.street }}
              <span v-if="data.order.streetNumber">{{
                data.order.streetNumber
              }}</span>
            </td>
          </tr>

          <tr v-if="data.zipCode">
            <td class="font-weight-bold">{{ $t("city") }}</td>
            <td>
              {{ data.zipCode }} <span v-if="data.town">{{ data.town }}</span>
            </td>
          </tr>

          <tr
            v-else-if="
              (data.order && data.order.zip) || (data.order && data.order.city)
            "
          >
            <td class="font-weight-bold">{{ $t("city") }}</td>
            <td>
              <span v-if="data.order.zip">
                {{ data.order.zip }}
              </span>
              <span v-if="data.order.city">
                {{ data.order.city }}
              </span>
            </td>
          </tr>

          <tr v-if="data.reachability">
            <td class="font-weight-bold">{{ $t("reachability") }}</td>
            <td>
              {{ $t(data.reachability) }}
            </td>
          </tr>

          <template v-if="data.customFields">
            <tr v-for="(field, index) in data.customFields" :key="index">
              <td class="font-weight-bold">{{ field.label }}</td>
              <td>{{ field.value }}</td>
            </tr>
          </template>

          <tr>
            <td class="font-weight-bold">{{ $t("campaign") }}</td>
            <td v-if="data.campaignsManually">
              <strong>{{ data.campaignsManually[0].manual_contacttype }}</strong
              ><br />
              <span class="caption"
                >{{ $t("manuallyCreatedBy") }}
                {{ data.campaignsManually[0].manual_data_admin_name }}</span
              >
            </td>
            <td v-else>{{ data.campaign.title }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("date") }}</td>
            <td v-if="data.created">
              {{ this.formatDate(data.created) }}
            </td>
          </tr>
        </tbody>
      </table>

      <span class="caption my-2">
        ID: {{ data.id }}<br />
        UUID: {{ data.uuid }}<br />
        Identity:{{ data.identityUuid }}
      </span>
    </v-card-text>
    <v-card-actions>
      <OptOutLead
        :id="data.id"
        :isOptOut="data.isOptOut"
        @updated="data.isOptOut = $event"
      />
      <v-spacer></v-spacer>
      <DeleteLead :id="data.id" />
    </v-card-actions>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import EditBasedata from "@/components/lead/manage/EditBasedata";
import DeleteLead from "@/components/lead/manage/DeleteLead";
import OptOutLead from "@/components/lead/manage/OptOutLead";
export default {
  name: "LeadBaseDataCard",

  props: ["id", "data"],

  components: {
    EditBasedata,
    DeleteLead,
    OptOutLead,
  },

  data() {
    return {
      pageTitle: "LeadBaseData",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      adminID: store.getters["auth/adminid"],
    };
  },

  methods: {
    isDeleteDisabled() {
      if (
        this.adminID === "1" ||
        this.adminID === "106" ||
        this.adminID === "836" ||
        this.adminID === "869"
      ) {
        return true;
      }

      return false;
    },
  },

  mixins: [helpers],
};
</script>
